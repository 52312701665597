<template>
  <div class="text-right">
    <v-dialog v-model="dialog" width="700px" height="auto">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="#2AB3A3"
          dark
          v-bind="attrs"
          v-on="on"
          style="height: 48px"
        >
          + เพิ่มรีวิว
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5">
          เพิ่มรีวิว
          <v-spacer></v-spacer>
          <v-btn @click="cancel()" icon><b>&#x2715;</b></v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="mt-5">
          <v-form
            ref="createForm"
            v-model="checkValidate"
            :lazy-validation="lazy"
          >
            <v-row>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">ชื่อผู้รีวิว (TH)</span>
                <span style="color: red">*</span>
                <v-text-field
                  hide-details
                  v-model="form.name_th"
                  placeholder="ระบุชื่อผู้รีวิว (TH)"
                  outlined
                  dense
                  :rules="rules.required"
                ></v-text-field
              ></v-col>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">ชื่อผู้รีวิว (EN)</span>
                <v-text-field
                  hide-details
                  v-model="form.name_en"
                  placeholder="ระบุชื่อผู้รีวิว (EN)"
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">รีวิว (TH)</span>
                <v-textarea
                  hide-details
                  v-model="form.desc_th"
                  placeholder="ระบุรีวิว (TH)"
                  outlined
                  dense
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">รีวิว (EN)</span>
                <v-textarea
                  hide-details
                  v-model="form.desc_en"
                  placeholder="ระบุรีวิว (EN)"
                  outlined
                  dense
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">ตำแหน่งและที่ทำงานปัจจุบัน (TH)</span>
                <span style="color: red">*</span>
                <v-text-field
                  hide-details
                  v-model="form.current_job_th"
                  placeholder="ระบุตำแหน่งและที่ทำงานปัจจุบัน (TH)"
                  outlined
                  dense
                  :rules="rules.required"
                ></v-text-field
              ></v-col>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">ตำแหน่งและที่ทำงานปัจจุบัน (EN)</span>
                <v-text-field
                  hide-details
                  v-model="form.current_job_en"
                  placeholder="ระบุตำแหน่งและที่ทำงานปัจจุบัน (EN)"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6" sm="6"
                ><span> วันที่ </span>
                <v-text-field
                  hide-details
                  v-model="form.date"
                  placeholder="ระบุวันที่"
                  outlined
                  dense
                ></v-text-field
              ></v-col>
            </v-row>

            <v-col cols="12" md="12" sm="12" class="ml-n3"
              ><span class="pt-2">รูปผู้รีวิว</span>
              <span style="color: red">*</span>
            </v-col>

            <v-row>
              <v-col cols="12" md="3" sm="3" xs="3" v-if="showImage">
                <v-img
                  :src="showImage"
                  max-width="150px"
                  max-height="150px"
                ></v-img>
              </v-col>
              <v-col cols="12" md="3" sm="3" xs="3" v-if="!showImage">
                <v-img
                  src="../../assets/noFile.png"
                  max-width="150px"
                  max-height="150px"
                ></v-img>
              </v-col>
              <v-col cols="12" md="9">
                <span>{{ namePic }}</span>
                <br />
                <span>ขนาดรูปที่แนะนำ: 1500 x 1500px</span>
                <br />
                <br />
                <br />
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  id="file"
                  ref="q_1_1"
                  v-on:change="submitfileMain('q_1_1')"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col cols="12" md="6" sm="6">
              <v-btn
                text
                style="border: solid 1px #d0d5dd; height: 44px"
                block
                @click="dialog = false"
              >
                ยกเลิก
              </v-btn>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-btn
                :loading="loading"
                @click="submit()"
                block
                dark
                color="#2AB3A3"
                style="height: 44px"
                >บันทึก</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Decode } from "@/services";
import { VueEditor } from "vue2-editor";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  props: {
    courseId: Number,
  },
  components: {
    Loading,
    VueEditor,
  },
  data() {
    return {
      loading: false,
      namePic: "",
      dialog: false,
      checkValidate: true,
      lazy: false,
      showImage: "",
      img_url: "",
      form: {
        name_th: null,
        name_en: null,
        current_job_th: null,
        current_job_en: null,
        desc_th: null,
        desc_en: null,
        date: null,
      },
      subItems: [
        { id: 1, text: "หมวดวิชาเสริมพื้นฐาน (เฉพาะภาคปกติ)" },
        { id: 2, text: "หมวดวิชาหลัก" },
        { id: 3, text: "หมวดวิชาเอก" },
        { id: 4, text: "หมวดวิชาเลือก" },
        { id: 5, text: "วิทยานิพนธ์/วิชาการค้นคว้าอิสระ" },
      ],

      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
    };
  },
  methods: {
    async handleFileUpload(questionNo) {
      // this.data[questionNo] = this.$refs[questionNo].files[0];
      // console.log("file", this.data[questionNo]);
    },

    async submitfileMain(questionNo) {
      if (this.$refs[questionNo].files[0]) {
        this.loading = true;
        let formData = new FormData();
        formData.append("files", this.$refs[questionNo].files[0]);
        formData.append("filename", this.$refs[questionNo].files[0].name);
        formData.append("type", "uploadfile");
        this.namePic = this.$refs[questionNo].files[0].name;
        // formData.append("userId", this.userId);
        console.log("formData", formData);
        const auth = {
          headers: {
            "Content-Type": "multipart/form-data",
            // Authorization: `Bearer` + " " + this.checkuser.token,
          },
        };
        console.log("auth", auth);
        const response = await this.axios.post(
          `https://gscm-service.yuzudigital.com/files/formData`,
          formData,
          auth
        );
        if (questionNo == "q_1_1") {
          this.img_url = response.data.data.path;
          this.showImage = response.data.data.path;
        }

        console.log("SUCCESS!!");
        console.log("response", response);

        this.loading = false;
      }
    },

    async submit(pic) {
      console.log(this.file, "f");
      if (this.$refs.createForm.validate(true)) {
        // await this.submitfileMain(pic);
        this.user = JSON.parse(
          Decode.decode(localStorage.getItem("gscmAdmin"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        const data = {
          courseId: this.courseId,
          name_th: this.form.name_th,
          name_en: this.form.name_en,
          current_job_th: this.form.current_job_th,
          current_job_en: this.form.current_job_en,
          desc_th: this.form.desc_th,
          desc_en: this.form.desc_en,
          date: this.form.date,
          img_url: this.img_url,
        };
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/reviews`,
          data,
          auth
        );
        console.log("createBanner", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `สร้างรีวิวสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$emit("getAllReview");
          this.cancel();
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    cancel() {
      this.$refs.createForm.reset();
      this.$refs.q_1_1.value = "";
      this.showImage = "";
      this.dialog = false;
    },
  },
};
</script>