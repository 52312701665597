<template>
  <div class="text-right">
    <v-dialog v-model="dialog" width="700px" height="auto">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="#2AB3A3"
          dark
          v-bind="attrs"
          v-on="on"
          style="height: 48px"
        >
          + เพิ่มรายวิชาใหม่
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5">
          เพิ่มรายวิชาใหม่
          <v-spacer></v-spacer>
          <v-btn @click="cancel()" icon><b>&#x2715;</b></v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="mt-5">
          <v-form
            ref="createForm"
            v-model="checkValidate"
            :lazy-validation="lazy"
          >
            <v-row>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">เลือกหมวดวิชา</span>
                <span style="color: red">*</span>
                <v-autocomplete
                  hide-details
                  v-model="form.subjectGroup"
                  placeholder="เลือกหมวดวิชา"
                  :items="subItems"
                  item-value="text"
                  outlined
                  dense
                  :rules="rules.required"
                >
                  <template v-slot:selection="{ item }">
                    {{ item.text }}
                  </template>
                  <template v-slot:item="{ item }">
                    {{ item.text }}
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">รหัสรายวิชา</span>
                <span style="color: red">*</span>
                <v-text-field
                  hide-details
                  v-model="form.subjectCode"
                  placeholder="ระบุรหัสรายวิชา"
                  outlined
                  dense
                  :rules="rules.required"
                ></v-text-field
              ></v-col>

              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">ชื่อรายวิชา (TH)</span>
                <span style="color: red">*</span>
                <v-text-field
                  hide-details
                  v-model="form.name_th"
                  placeholder="ระบุชื่อรายวิชา (TH)"
                  outlined
                  dense
                  :rules="rules.required"
                ></v-text-field
              ></v-col>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">ชื่อรายวิชา (EN)</span>
                <v-text-field
                  hide-details
                  v-model="form.name_en"
                  placeholder="ระบุชื่อรายวิชา (EN)"
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">คำอธิบาย (TH)</span>
                <span style="color: red">*</span>
                <v-textarea
                  hide-details
                  v-model="form.description_th"
                  placeholder="ระบุคำอธิบาย (TH)"
                  outlined
                  dense
                  :rules="rules.required"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">คำอธิบาย (EN)</span>
                <v-textarea
                  hide-details
                  v-model="form.description_en"
                  placeholder="ระบุคำอธิบาย (EN)"
                  outlined
                  dense
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col cols="12" md="6" sm="6">
              <v-btn block @click="dialog = false"> ยกเลิก </v-btn>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-btn @click="submit()" block dark color="#2AB3A3">บันทึก</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>

        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="submit()" dark color="#2AB3A3">บันทึก</v-btn>
          <v-btn color="#2AB3A3" dark @click="dialog = false"> ปิด </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Decode } from "@/services";
import { VueEditor } from "vue2-editor";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  props: {
    courseId: Number,
  },
  components: {
    Loading,
    VueEditor,
  },
  data() {
    return {
      loading: false,
      dialog: false,
      checkValidate: true,
      lazy: false,
      form: {
        subjectGroup: null,
        subjectCode: null,
        name_th: null,
        name_en: null,
        description_th: null,
        description_en: null,
      },
      subItems: [
        { id: 1, text: "หมวดวิชาเสริมพื้นฐาน (เฉพาะภาคปกติ)" },
        { id: 2, text: "หมวดวิชาหลัก" },
        { id: 3, text: "หมวดวิชาเอก" },
        { id: 4, text: "หมวดวิชาเลือก" },
        { id: 5, text: "วิทยานิพนธ์/วิชาการค้นคว้าอิสระ" },
      ],

      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },

      itemsCourse: [],
      MajorSubData: [],
    };
  },
  methods: {
    async submitfileMain(questionNo) {
      if (this.$refs[questionNo].files[0]) {
        let formData = new FormData();
        formData.append("files", this.$refs[questionNo].files[0]);
        formData.append("filename", this.$refs[questionNo].files[0].name);
        formData.append("type", "uploadfile");
        // formData.append("userId", this.userId);
        console.log("formData", formData);
        const auth = {
          headers: {
            "Content-Type": "multipart/form-data",
            // Authorization: `Bearer` + " " + this.checkuser.token,
          },
        };
        console.log("auth", auth);
        const response = await this.axios.post(
          `https://gscm-service.yuzudigital.com/files/formData`,
          formData,
          auth
        );
        if (questionNo == "q_1_1") {
          this.img_url = response.data.data.path;
          this.showImage = response.data.data.path;
        }

        console.log("SUCCESS!!");
        console.log("response", response);
      }
    },

    async submit() {
      if (!this.courseId) {
        this.$swal.fire({
          icon: "error",
          text: `กรุณากรอกฟอร์มเกี่ยวกับหลักสูตรก่อน`,
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        console.log(this.file, "f");
        if (this.$refs.createForm.validate(true)) {
          this.user = JSON.parse(
            Decode.decode(localStorage.getItem("gscmAdmin"))
          );
          const auth = {
            headers: { Authorization: `Bearer ${this.user.token}` },
          };
          const data = {
            courseId: this.courseId,
            subjectGroup: this.form.subjectGroup,
            subjectCode: this.form.subjectCode,
            name_th: this.form.name_th,
            name_en: this.form.name_en,
            description_th: this.form.description_th,
            description_en: this.form.description_en,
          };
          const response = await this.axios.post(
            `${process.env.VUE_APP_API}/coursesOffereds`,
            data,
            auth
          );
          console.log("createBanner", response);
          console.log(response.data.response_status);
          if (response.data.response_status == "SUCCESS") {
            this.$swal.fire({
              icon: "success",
              text: `สร้างรายวิชาใหม่สำเร็จ`,
              showConfirmButton: false,
              timer: 1500,
            });
            this.$emit("getMajorSub");
            this.cancel();
          } else {
            this.$swal.fire({
              icon: "error",
              text: response.data.response_description,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }
      }
    },
    cancel() {
      this.$refs.createForm.reset();
      this.dialog = false;
    },
  },
};
</script>