<template>
  <div class="text-right">
    <v-dialog v-model="dialog" width="700px" height="auto">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="#2AB3A3"
          dark
          v-bind="attrs"
          v-on="on"
          style="height: 48px"
        >
          + เพิ่มค่าเล่าเรียน
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5">
          เพิ่มค่าเล่าเรียน
          <v-spacer></v-spacer>
          <v-btn @click="cancel()" icon><b>&#x2715;</b></v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="mt-5">
          <v-form
            ref="createForm"
            v-model="checkValidate"
            :lazy-validation="lazy"
          >
            <v-row>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">ชื่อประเภทค่าเล่าเรียน (TH)</span>
                <span style="color: red">*</span>
                <v-text-field
                  hide-details
                  v-model="form.name_th"
                  placeholder="ระบุชื่อประเภทค่าเล่าเรียน (TH)"
                  outlined
                  dense
                  :rules="rules.required"
                ></v-text-field
              ></v-col>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">ชื่อประเภทค่าเล่าเรียน (EN)</span>
                <v-text-field
                  hide-details
                  v-model="form.name_en"
                  placeholder="ระบุชื่อประเภทค่าเล่าเรียน (EN)"
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="12" sm="12"
                ><span class="pt-2">รูปแบบหลักสูตร(ถ้ามี)</span>
                <v-select
                  v-model="form.typeCourseId"
                  :items="itemsMajorType"
                  placeholder="เลือกรูปแบบหลักสูตร"
                  item-value="id"
                  dense
                  outlined
                  hide-details
                >
                  <template v-slot:selection="{ item }">
                    {{ item.name_th }} - {{ item.name_en }}
                  </template>
                  <template v-slot:item="{ item }">
                    {{ item.name_th }} - {{ item.name_en }}
                  </template>
                </v-select>
              </v-col>

              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">ราคาต่อหน่วยกิต</span>
                <span style="color: red">*</span>
                <v-text-field
                  hide-details
                  v-model="form.pricePerUnit"
                  placeholder="ระบุราคาต่อหน่วยกิต"
                  outlined
                  dense
                  :rules="rules.required"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">ค่าใช้จ่ายตลอดหลักสูตร (โดยประมาณ)</span>
                <span style="color: red">*</span>
                <v-text-field
                  hide-details
                  v-model="form.allCost"
                  placeholder="ระบุค่าใช้จ่ายตลอดหลักสูตร"
                  outlined
                  dense
                  :rules="rules.required"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col cols="12" md="6" sm="6">
              <v-btn
                text
                style="border: solid 1px #d0d5dd; height: 44px"
                block
                @click="dialog = false"
              >
                ยกเลิก
              </v-btn>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-btn
                :loading="loading"
                @click="submit()"
                block
                dark
                color="#2AB3A3"
                style="height: 44px"
                >บันทึก</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>

        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="submit()" dark color="#2AB3A3">บันทึก</v-btn>
          <v-btn color="#2AB3A3" dark @click="dialog = false"> ปิด </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Decode } from "@/services";
import { VueEditor } from "vue2-editor";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  props: {
    courseId: Number,
    itemsMajorType: Array,
  },
  components: {
    Loading,
    VueEditor,
  },
  data() {
    return {
      loading: false,
      dialog: false,
      checkValidate: true,
      lazy: false,
      form: {
        type: 1,
        name_th: null,
        name_en: null,
        typeCourseId: null,
        pricePerUnit: null,
        allCost: null,
      },

      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
    };
  },
  watch: {
    dialog(visible) {
      if (visible) {
      }
    },
  },
  methods: {
    async submit() {
      console.log(this.file, "f");
      if (this.$refs.createForm.validate(true)) {
        this.user = JSON.parse(
          Decode.decode(localStorage.getItem("gscmAdmin"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        const data = {
          courseId: this.courseId,
          ...this.form,
        };
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/studyFees`,
          data,
          auth
        );
        console.log("createBanner", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `สร้างค่าเล่าเรียนใหม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$emit("getNormalCost");
          this.cancel();
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    cancel() {
      this.$refs.createForm.reset();
      this.dialog = false;
    },
  },
};
</script>

