<template>
  <div class="text-right">
    <v-dialog v-model="dialog" width="700px" height="auto">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="#2AB3A3"
          dark
          v-bind="attrs"
          v-on="on"
          style="height: 48px"
        >
          + เพิ่มสาขาเอก
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5">
          เพิ่มสาขาเอก
          <v-spacer></v-spacer>
          <v-btn @click="cancel()" icon><b>&#x2715;</b></v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="mt-5">
          <v-form
            ref="createForm"
            v-model="checkValidate"
            :lazy-validation="lazy"
          >
            <v-row>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">ชื่อสาขาเอก (TH)</span>
                <span style="color: red">*</span>
                <v-text-field
                  hide-details
                  v-model="form.name_th"
                  placeholder="ระบุชื่อสาขาเอก (TH)"
                  outlined
                  dense
                  :rules="rules.required"
                ></v-text-field
              ></v-col>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">ชื่อสาขาเอก (EN)</span>
                <v-text-field
                  hide-details
                  v-model="form.name_en"
                  placeholder="ระบุชื่อสาขาเอก (EN)"
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">หมายเหตุ (TH)</span>
                <span style="color: red">*</span>
                <v-text-field
                  hide-details
                  v-model="form.note_th"
                  placeholder="หมายเหตุ (TH)"
                  outlined
                  dense
                  :rules="rules.required"
                ></v-text-field
              ></v-col>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">หมายเหตุ (EN)</span>
                <v-text-field
                  hide-details
                  v-model="form.note_en"
                  placeholder="หมายเหตุ (EN)"
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">โอกาสในการประกอบอาชีพ (TH)</span>
                <span style="color: red">*</span>
                <quill-editor
                  class="quill233"
                  v-model="form.chance_th"
                  :content="content"
                  :options="editorOption"
                  @change="onEditorChange($event)"
                />
              </v-col>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">โอกาสในการประกอบอาชีพ (EN)</span>
                <quill-editor
                  class="quill233"
                  v-model="form.chance_en"
                  :content="content1"
                  :options="editorOption"
                  @change="onEditorChange1($event)"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <span class="text-h5" style="color: black"> รายวิชา </span>
              </v-col>

              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-row
                    v-for="(element, index1) in subject"
                    :key="index1"
                    class="mb-n8"
                  >
                    <v-col cols="10" md="10" sm="10" class="ml-2">
                      <v-autocomplete
                        hide-details
                        v-model="element.subjectId"
                        placeholder="ระบุรายวิชา"
                        :items="itemsMajorSubStable"
                        item-value="id"
                        dense
                        outlined
                      >
                        <template v-slot:selection="{ item }">
                          {{ item.subjectCode }} - {{ item.name_th }} -
                          {{ item.name_en }}
                        </template>
                        <template v-slot:item="{ item }">
                          {{ item.subjectCode }} - {{ item.name_th }} -
                          {{ item.name_en }}
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col
                      cols="2"
                      md="2"
                      sm="2"
                      v-if="element.length != 1"
                      class="ml-n2"
                    >
                      <v-btn @click="deleteSubject(index1)" icon>
                        <v-icon color="#F25247"
                          >mdi-minus-circle-outline</v-icon
                        >
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="12" sm="12" class="ml-2">
                  <v-btn
                    @click="addSubject()"
                    text
                    color="#2ab3a3"
                    style="border: solid 1px #2ab3a3; border-radius: 0px"
                    >+ เพิ่ม</v-btn
                  >
                </v-col>
              </v-row>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col cols="12" md="6" sm="6">
              <v-btn block @click="dialog = false"> ยกเลิก </v-btn>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-btn @click="submit()" block dark color="#2AB3A3">บันทึก</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>

        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="submit()" dark color="#2AB3A3">บันทึก</v-btn>
          <v-btn color="#2AB3A3" dark @click="dialog = false"> ปิด </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Decode } from "@/services";
import { VueEditor } from "vue2-editor";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  props: {
    courseId: Number,
    itemsMajorSub: Array,
  },
  components: {
    Loading,
    VueEditor,
  },
  data() {
    return {
      loading: false,
      itemsMajorSubStable: [],

      subject: [{ subjectId: null }],

      dialog: false,
      checkValidate: true,
      lazy: false,
      form: {
        name_th: null,
        name_en: null,
        note_th: null,
        note_en: null,
        chance_th: null,
        chance_en: null,
      },
      subItems: [
        { id: 1, text: "หมวดวิชาเสริมพื้นฐาน (เฉพาะภาคปกติ)" },
        { id: 2, text: "หมวดวิชาหลัก" },
        { id: 3, text: "หมวดวิชาเอก" },
        { id: 4, text: "หมวดวิชาเลือก" },
        { id: 5, text: "วิทยานิพนธ์/วิชาการค้นคว้าอิสระ" },
      ],

      content: "",
      content1: "",

      editorOption: {
        placeholder: "ระบุข้อมูล",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            [
              "link",
              "image",
              // "video"
            ],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },

      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },

      itemsCourse: [],
      MajorSubData: [],
    };
  },
  watch: {
    dialog(visible) {
      if (visible) {
        this.itemsMajorSubStable = this.itemsMajorSub;
        console.log("this.itemsMajorSub", this.itemsMajorSub);
      }
    },
  },
  methods: {
    addSubject() {
      this.subject.push({
        subjectId: null,
      });
    },
    deleteSubject(index1) {
      this.subject.splice(index1, 1);
    },
    async getMajorSub() {
      this.loading = true;
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        // `${process.env.VUE_APP_API}/coursesOffereds`,
        `${process.env.VUE_APP_API}/coursesOffereds?courseId=${this.courseId}`,
        auth
        // 'http://localhost:8081/types/'
      );
      console.log("getMajorSub", response.data.data);
      // this.itemsMajorSub = response.data.data;
      this.itemsMajorSubStable = response.data.data;
      // this.imgUrl = response.data.data.imgUrl;
      for (let i in this.itemsMajorSub) {
        this.itemsMajorSub[i].sortindex = parseInt(i) + 1;
      }
      this.loading = false;
    },
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
    onEditorChange1({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content1 = html;
    },
    async submitfileMain(questionNo) {
      if (this.$refs[questionNo].files[0]) {
        let formData = new FormData();
        formData.append("files", this.$refs[questionNo].files[0]);
        formData.append("filename", this.$refs[questionNo].files[0].name);
        formData.append("type", "uploadfile");
        // formData.append("userId", this.userId);
        console.log("formData", formData);
        const auth = {
          headers: {
            "Content-Type": "multipart/form-data",
            // Authorization: `Bearer` + " " + this.checkuser.token,
          },
        };
        console.log("auth", auth);
        const response = await this.axios.post(
          `https://gscm-service.yuzudigital.com/files/formData`,
          formData,
          auth
        );
        if (questionNo == "q_1_1") {
          this.img_url = response.data.data.path;
          this.showImage = response.data.data.path;
        }

        console.log("SUCCESS!!");
        console.log("response", response);
      }
    },

    async submit() {
      console.log(this.file, "f");
      if (this.$refs.createForm.validate(true)) {
        this.user = JSON.parse(
          Decode.decode(localStorage.getItem("gscmAdmin"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        const data = {
          courseId: this.courseId,
          subject: this.subject,
          ...this.form,
        };
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/majorNews`,
          data,
          auth
        );
        console.log("createBanner", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `สร้างสาขาเอกใหม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$emit("getMajorMain");
          this.cancel();
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    cancel() {
      this.$refs.createForm.reset();
      this.content = "";
      this.content1 = "";
      this.dialog = false;
    },
  },
};
</script>