<template>
  <div class="text-right">
    <v-dialog
      v-model="openDialogEditHopefuls"
      persistent
      width="700px"
      height="auto"
      @click:outside="cancel()"
      @keydown.esc="cancel()"
    >
      <v-card>
        <v-card-title class="text-h5">
          เพิ่มผลการเรียนรู้ที่คาดหวัง
          <v-spacer></v-spacer>
          <v-btn @click="cancel()" icon><b>&#x2715;</b></v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="mt-5">
          <v-form
            ref="createForm"
            v-model="checkValidate"
            :lazy-validation="lazy"
          >
            <v-row>
              <v-col cols="12" md="6" sm="6">
                <span> ผลการเรียนรู้ที่คาดหวัง (TH) </span>
                <span style="color: red">*</span>
                <v-textarea
                  placeholder="ระบุผลการเรียนรู้ที่คาดหวัง (TH)"
                  v-model="form.description_th"
                  auto-grow
                  dense
                  outlined
                  hide-details
                  :rules="rules.required"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <span> ผลการเรียนรู้ที่คาดหวัง (EN) </span>
                <v-textarea
                  placeholder="ระบุผลการเรียนรู้ที่คาดหวัง (EN)"
                  v-model="form.description_en"
                  auto-grow
                  dense
                  outlined
                  hide-details
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col cols="12" md="6" sm="6">
              <v-btn
                text
                style="border: solid 1px #d0d5dd; height: 44px"
                block
                @click="cancel()"
              >
                ยกเลิก
              </v-btn>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-btn
                :loading="loading"
                @click="submit()"
                block
                dark
                color="#2AB3A3"
                style="height: 44px"
                >บันทึก</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Decode } from "@/services";
import { VueEditor } from "vue2-editor";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  props: {
    openDialogEditHopefuls: Boolean,
    itemsHopefulEdit: Object,
  },
  components: {
    Loading,
    VueEditor,
  },
  data() {
    return {
      loading: false,
      dialog: false,
      checkValidate: true,
      lazy: false,
      form: {
        description_th: null,
        description_en: null,
      },
      subItems: [
        { id: 1, text: "หมวดวิชาเสริมพื้นฐาน (เฉพาะภาคปกติ)" },
        { id: 2, text: "หมวดวิชาหลัก" },
        { id: 3, text: "หมวดวิชาเอก" },
        { id: 4, text: "หมวดวิชาเลือก" },
        { id: 5, text: "วิทยานิพนธ์/วิชาการค้นคว้าอิสระ" },
      ],

      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
    };
  },
  watch: {
    openDialogEditHopefuls(visible) {
      if (visible) {
        this.form = this.itemsHopefulEdit;
      }
    },
  },
  methods: {
    async submit() {
      this.user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const data = {
        courseId: this.form.courseId,
        description_th: this.form.description_th,
        description_en: this.form.description_en,
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/expectedLearningOutcomes/${this.itemsHopefulEdit.id}`,
        // this.form,
        data,
        auth
      );
      console.log(response.data.response_status);
      if (response.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: `แก้ไขผลการเรียนที่รู้คาดหวังสำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.$refs.createForm.reset();
        this.$emit("getAllHopeful");
        this.cancel();
      } else {
        this.$swal.fire({
          icon: "error",
          text: response.data.response_description,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    cancel() {
      this.$refs.createForm.reset();
      this.$emit("closeDialogEditHopefuls");
    },
  },
};
</script>