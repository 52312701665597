<template>
  <div class="text-right">
    <v-dialog
      v-model="openDialogCourseStructureEdit"
      width="700px"
      height="auto"
      @click:outside="cancel()"
      @keydown.esc="cancel()"
    >
      <v-card>
        <v-card-title class="text-h5">
          เพิ่มรูปแบบโครงสร้างหลักสูตร
          <v-spacer></v-spacer>
          <v-btn @click="cancel()" icon><b>&#x2715;</b></v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="mt-5">
          <v-form
            ref="createForm"
            v-model="checkValidate"
            :lazy-validation="lazy"
          >
            <v-row>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">ชื่อโครงสร้างหลักสูตร (TH)</span>
                <span style="color: red">*</span>
                <v-text-field
                  hide-details
                  v-model="form.name_th"
                  placeholder="ระบุชื่อโครงสร้างหลักสูตร (TH)"
                  outlined
                  dense
                  :rules="rules.required"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">ชื่อโครงสร้างหลักสูตร (EN)</span>
                <v-text-field
                  hide-details
                  v-model="form.name_en"
                  placeholder="ระบุชื่อโครงสร้างหลักสูตร (EN)"
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">หมายเหตุ (TH)</span>
                <span style="color: red">*</span>
                <v-text-field
                  hide-details
                  v-model="form.note_th"
                  placeholder="ระบุหมายเหตุ (TH)"
                  outlined
                  dense
                  :rules="rules.required"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">หมายเหตุ (EN)</span>
                <v-text-field
                  hide-details
                  v-model="form.note_en"
                  placeholder="ระบุหมายเหตุ (EN)"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12" sm="12">
                <span class="text-h5" style="color: black"> หน่วยกิต </span>
              </v-col>

              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">วิชาเสริมพื้นฐาน</span>
                <span style="color: red">*</span>
                <v-text-field
                  hide-details
                  v-model="form.countUnit1"
                  placeholder="ระบุหน่วยกิตวิชาเสริมพื้นฐาน"
                  outlined
                  dense
                  :rules="rules.required"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" sm="6"></v-col>

              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">วิชาพื้นฐาน</span>
                <span style="color: red">*</span>
                <v-text-field
                  hide-details
                  v-model="form.countUnit2"
                  placeholder="ระบุหน่วยกิตวิชาพื้นฐาน"
                  outlined
                  dense
                  :rules="rules.required"
                ></v-text-field
              ></v-col>
              <v-col cols="12" md="6" sm="6"></v-col>

              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">วิชาหลัก</span>
                <span style="color: red">*</span>
                <v-text-field
                  hide-details
                  v-model="form.countUnit3"
                  placeholder="ระบุหน่วยกิตวิชาหลัก"
                  outlined
                  dense
                  :rules="rules.required"
                ></v-text-field
              ></v-col>
              <v-col cols="12" md="6" sm="6"></v-col>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">วิชาระเบียบวิจัย</span>
                <span style="color: red">*</span>
                <v-text-field
                  hide-details
                  v-model="form.countUnit4"
                  placeholder="ระบุหน่วยกิตวิชาระเบียบวิจัย"
                  outlined
                  dense
                  :rules="rules.required"
                ></v-text-field
              ></v-col>
              <v-col cols="12" md="6" sm="6"></v-col>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">วิชาเลือก</span>
                <span style="color: red">*</span>
                <v-text-field
                  hide-details
                  v-model="form.countUnit5"
                  placeholder="ระบุหน่วยกิตวิชาเลือก"
                  outlined
                  dense
                  :rules="rules.required"
                ></v-text-field
              ></v-col>
              <v-col cols="12" md="6" sm="6"> </v-col>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">สอบวัดคุณสมบัติ</span>
                <span style="color: red">*</span>
                <v-text-field
                  hide-details
                  v-model="form.countUnit6"
                  placeholder="ระบุหน่วยกิตสอบวัดคุณสมบัติ"
                  outlined
                  dense
                  :rules="rules.required"
                ></v-text-field
              ></v-col>
              <v-col cols="12" md="6" sm="6"> </v-col>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">วิทยานิพนธ์ดุษฎีบัณฑิต</span>
                <span style="color: red">*</span>
                <v-text-field
                  hide-details
                  v-model="form.countUnit7"
                  placeholder="ระบุหน่วยกิตวิทยานิพนธ์ดุษฎีบัณฑิต"
                  outlined
                  dense
                  :rules="rules.required"
                ></v-text-field
              ></v-col>
              <v-col cols="12" md="6" sm="6"> </v-col>
              <v-col cols="12" md="6" sm="6"
                ><span class="pt-2">รวม</span>
                <span style="color: red">*</span>
                <v-text-field
                  hide-details
                  v-model="form.summary"
                  placeholder="ระบุหน่วยกิตรวม"
                  outlined
                  dense
                  :rules="rules.required"
                ></v-text-field
              ></v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col cols="12" md="6" sm="6">
              <v-btn
                text
                style="border: solid 1px #d0d5dd; height: 44px"
                block
                @click="cancel()"
              >
                ยกเลิก
              </v-btn>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-btn
                :loading="loading"
                @click="submit()"
                block
                dark
                color="#2AB3A3"
                style="height: 44px"
                >บันทึก</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>

        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="submit()" dark color="#2AB3A3">บันทึก</v-btn>
          <v-btn color="#2AB3A3" dark @click="cancel()"> ปิด </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Decode } from "@/services";
import { VueEditor } from "vue2-editor";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  props: {
    courseId: Number,
    openDialogCourseStructureEdit: Boolean,
    itemDialogCourseStructureEdit: Object,
  },
  components: {
    Loading,
    VueEditor,
  },
  data() {
    return {
      loading: false,
      itemsMajorSubStable: [],

      dialog: false,
      checkValidate: true,
      lazy: false,
      form: {
        name_th: null,
        name_en: null,
        note_th: null,
        note_en: null,
        countUnit1: null,
        countUnit2: null,
        countUnit3: null,
        countUnit4: null,
        countUnit5: null,
        countUnit6: null,
        countUnit7: null,
        summary: null,
      },
      subItems: [
        { id: 1, text: "หมวดวิชาเสริมพื้นฐาน (เฉพาะภาคปกติ)" },
        { id: 2, text: "หมวดวิชาหลัก" },
        { id: 3, text: "หมวดวิชาเอก" },
        { id: 4, text: "หมวดวิชาเลือก" },
        { id: 5, text: "วิทยานิพนธ์/วิชาการค้นคว้าอิสระ" },
      ],

      content: "",
      content1: "",

      editorOption: {
        placeholder: "ระบุข้อมูล",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            [
              "link",
              "image",
              // "video"
            ],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },

      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },

      itemsCourse: [],
      MajorSubData: [],
    };
  },
  watch: {
    openDialogCourseStructureEdit(visible) {
      if (visible) {
        this.form = this.itemDialogCourseStructureEdit;
      }
    },
  },
  methods: {
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
    onEditorChange1({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content1 = html;
    },
    async submitfileMain(questionNo) {
      if (this.$refs[questionNo].files[0]) {
        let formData = new FormData();
        formData.append("files", this.$refs[questionNo].files[0]);
        formData.append("filename", this.$refs[questionNo].files[0].name);
        formData.append("type", "uploadfile");
        // formData.append("userId", this.userId);
        console.log("formData", formData);
        const auth = {
          headers: {
            "Content-Type": "multipart/form-data",
            // Authorization: `Bearer` + " " + this.checkuser.token,
          },
        };
        console.log("auth", auth);
        const response = await this.axios.post(
          `https://gscm-service.yuzudigital.com/files/formData`,
          formData,
          auth
        );
        if (questionNo == "q_1_1") {
          this.img_url = response.data.data.path;
          this.showImage = response.data.data.path;
        }

        console.log("SUCCESS!!");
        console.log("response", response);
      }
    },

    async submit() {
      console.log(this.file, "f");
      if (this.$refs.createForm.validate(true)) {
        this.user = JSON.parse(
          Decode.decode(localStorage.getItem("gscmAdmin"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        const data = {
          courseId: this.courseId,
          ...this.form,
        };
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/subjectStructures/${this.itemDialogCourseStructureEdit.id}`,
          data,
          auth
        );
        console.log("createBanner", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `แก้ไขสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$emit("getCourseStructure");
          this.cancel();
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    cancel() {
      this.$refs.createForm.reset();
      this.$emit("closeDialogCourseStructureEdit");
    },
  },
};
</script>

